<template>
    <button
        class="button--label menu-button"
        @click="mainStore.toggleMenuOpen(true)"
    >
        <div class="sr-only">{{ $t('menu.open-menu-sr') }}</div>

        <span aria-hidden="true">{{ $t('menu.open-menu') }}</span>

        <BaseIcon icon="circle" />
    </button>
</template>

<script setup>
const mainStore = useMainStore();
</script>

<style lang="less" src="./MenuButton.less" />
