<template>
    <div class="animation-switch">
        <label>
            <input
                id="animationSwitch"
                type="checkbox"
                class="sr-only"
                :checked="!mainStore.preferReducedMotion"
                @change="mainStore.toggleReducedMotion()"
            >
            <div class="animation-switch__slider animation-switch__round" />
        </label>
        <label for="animationSwitch" aria-hidden="true"><span class="sr-only">{{ mainStore.preferReducedMotion ? $t('motion.enable-animate-sr') : $t('motion.disable-animate-sr') }}</span> {{ $t('motion.autoplay') }}</label>
    </div>
</template>

<script setup>
const mainStore = useMainStore();
const reducedMotion = usePreferredReducedMotion();

watch(reducedMotion, (value) => {
    if (value === 'reduce') {
        mainStore.setReducedMotion(true);
    } else {
        mainStore.setReducedMotion(false);
    }
}, { immediate: true });
</script>

<style lang="less" src="./AnimationSwitch.less" />
